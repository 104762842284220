(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

require("./app/modules/postfix");

require("./app/modules/toast");

require("./app/modules/storage");

require("./app/modules/modal");

require("./app/modules/clipboard");

var _config = _interopRequireDefault(require("./app/config"));

var _run = _interopRequireDefault(require("./app/run"));

var _inArray = _interopRequireDefault(require("./app/filters/inArray"));

var _telegram = _interopRequireDefault(require("./app/filters/telegram"));

var _limiter = _interopRequireDefault(require("./app/filters/limiter"));

var _money = _interopRequireDefault(require("./app/filters/money"));

var _prefix = _interopRequireDefault(require("./app/filters/prefix"));

var _suffix = _interopRequireDefault(require("./app/filters/suffix"));

var _declension = _interopRequireDefault(require("./app/filters/declension"));

var _cutter = _interopRequireDefault(require("./app/filters/cutter"));

var _sxMenu = _interopRequireDefault(require("./app/directives/sxMenu"));

var _HomeCtrl = _interopRequireDefault(require("./app/controllers/HomeCtrl"));

var _TickerCtrl = _interopRequireDefault(require("./app/controllers/TickerCtrl"));

var _OfferCtrl = _interopRequireDefault(require("./app/controllers/OfferCtrl"));

var _AllNewsCtrl = _interopRequireDefault(require("./app/controllers/AllNewsCtrl"));

var _NewsCtrl = _interopRequireDefault(require("./app/controllers/NewsCtrl"));

var _FaqCtrl = _interopRequireDefault(require("./app/controllers/FaqCtrl"));

var _ContactsCtrl = _interopRequireDefault(require("./app/controllers/ContactsCtrl"));

var _sxCaptcha = _interopRequireDefault(require("./app/directives/sxCaptcha"));

var _sxSubmit = _interopRequireDefault(require("./app/directives/sxSubmit"));

var _SignupCtrl = _interopRequireDefault(require("./app/controllers/SignupCtrl"));

var _sxAutofocus = _interopRequireDefault(require("./app/directives/sxAutofocus"));

var _SigninCtrl = _interopRequireDefault(require("./app/controllers/SigninCtrl"));

var _ResetCtrl = _interopRequireDefault(require("./app/controllers/ResetCtrl"));

var _sxNiceScroll = _interopRequireDefault(require("./app/directives/sxNiceScroll"));

var _sxBurger = _interopRequireDefault(require("./app/directives/sxBurger"));

var _sxAvatar = _interopRequireDefault(require("./app/directives/sxAvatar"));

var _DashCtrl = _interopRequireDefault(require("./app/controllers/DashCtrl"));

var _DepositStatsCtrl = _interopRequireDefault(require("./app/controllers/DepositStatsCtrl"));

var _ReferralStatsCtrl = _interopRequireDefault(require("./app/controllers/ReferralStatsCtrl"));

var _LatestDepositsCtrl = _interopRequireDefault(require("./app/controllers/LatestDepositsCtrl"));

var _DepositCtrl = _interopRequireDefault(require("./app/controllers/DepositCtrl"));

var _sxAmount = _interopRequireDefault(require("./app/directives/sxAmount"));

var _WithdrawalCtrl = _interopRequireDefault(require("./app/controllers/WithdrawalCtrl"));

var _DepositsCtrl = _interopRequireDefault(require("./app/controllers/DepositsCtrl"));

var _HistoryCtrl = _interopRequireDefault(require("./app/controllers/HistoryCtrl"));

var _PartnersCtrl = _interopRequireDefault(require("./app/controllers/PartnersCtrl"));

var _PartnersHistoryCtrl = _interopRequireDefault(require("./app/controllers/PartnersHistoryCtrl"));

var _PromoCtrl = _interopRequireDefault(require("./app/controllers/PromoCtrl"));

var _sxStyler = _interopRequireDefault(require("./app/directives/sxStyler"));

var _SettingsPersonalCtrl = _interopRequireDefault(require("./app/controllers/SettingsPersonalCtrl"));

var _SettingsAccountsCtrl = _interopRequireDefault(require("./app/controllers/SettingsAccountsCtrl"));

var _SettingsSecurityCtrl = _interopRequireDefault(require("./app/controllers/SettingsSecurityCtrl"));

var _PromoToolsCtrl = _interopRequireDefault(require("./app/controllers/PromoToolsCtrl"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

if (window.self !== window.top) window.top.location.href = window.location.href;
var Requires = ['ngSanitize', 'sx.postfix', 'sx.clipboard', 'sx.storage', //'sx.sessionStorage',
'sx.modal', 'sx.toast'];
window.ACCOUNT_API = '/account/api';
window.LANG = document.documentElement.lang;

window.googleTranslateInit = function () {
  return new google.translate.TranslateElement({
    pageLanguage: LANG
  });
};

var app = angular.module('trustaking', Requires, _config.default).run(_run.default); // filters

app.filter('inArray', _inArray.default);
app.filter('prefix', _prefix.default);
app.filter('suffix', _suffix.default);
app.filter('money', _money.default);
app.filter('cutter', _cutter.default);
app.filter('limiter', _limiter.default);
app.filter('telegram', _telegram.default);
app.filter('declension', _declension.default); // directives

app.directive('sxMenu', _sxMenu.default);
app.directive('sxCaptcha', _sxCaptcha.default);
app.directive('sxSubmit', _sxSubmit.default);
app.directive('sxAutofocus', _sxAutofocus.default);
app.directive('sxNiceScroll', _sxNiceScroll.default);
app.directive('sxBurger', _sxBurger.default);
app.directive('sxAvatar', _sxAvatar.default);
app.directive('sxAmount', _sxAmount.default);
app.directive('sxStyler', _sxStyler.default); // controllers

app.controller('HomeCtrl', _HomeCtrl.default);
app.controller('TickerCtrl', _TickerCtrl.default);
app.controller('OfferCtrl', _OfferCtrl.default);
app.controller('AllNewsCtrl', _AllNewsCtrl.default);
app.controller('NewsCtrl', _NewsCtrl.default);
app.controller('FaqCtrl', _FaqCtrl.default);
app.controller('ContactsCtrl', _ContactsCtrl.default);
app.controller('SigninCtrl', _SigninCtrl.default);
app.controller('SignupCtrl', _SignupCtrl.default);
app.controller('ResetCtrl', _ResetCtrl.default);
app.controller('DashCtrl', _DashCtrl.default);
app.controller('DepositStatsCtrl', _DepositStatsCtrl.default);
app.controller('ReferralStatsCtrl', _ReferralStatsCtrl.default);
app.controller('LatestDepositsCtrl', _LatestDepositsCtrl.default);
app.controller('DepositCtrl', _DepositCtrl.default);
app.controller('DepositsCtrl', _DepositsCtrl.default);
app.controller('WithdrawalCtrl', _WithdrawalCtrl.default);
app.controller('HistoryCtrl', _HistoryCtrl.default);
app.controller('PartnersCtrl', _PartnersCtrl.default);
app.controller('PartnersHistoryCtrl', _PartnersHistoryCtrl.default);
app.controller('PromoCtrl', _PromoCtrl.default);
app.controller('PromoToolsCtrl', _PromoToolsCtrl.default);
app.controller('SettingsPersonalCtrl', _SettingsPersonalCtrl.default);
app.controller('SettingsAccountsCtrl', _SettingsAccountsCtrl.default);
app.controller('SettingsSecurityCtrl', _SettingsSecurityCtrl.default); // make app global

window.app = app;

},{"./app/config":2,"./app/controllers/AllNewsCtrl":3,"./app/controllers/ContactsCtrl":4,"./app/controllers/DashCtrl":5,"./app/controllers/DepositCtrl":6,"./app/controllers/DepositStatsCtrl":7,"./app/controllers/DepositsCtrl":8,"./app/controllers/FaqCtrl":9,"./app/controllers/HistoryCtrl":10,"./app/controllers/HomeCtrl":11,"./app/controllers/LatestDepositsCtrl":12,"./app/controllers/NewsCtrl":13,"./app/controllers/OfferCtrl":14,"./app/controllers/PartnersCtrl":15,"./app/controllers/PartnersHistoryCtrl":16,"./app/controllers/PromoCtrl":17,"./app/controllers/PromoToolsCtrl":18,"./app/controllers/ReferralStatsCtrl":19,"./app/controllers/ResetCtrl":20,"./app/controllers/SettingsAccountsCtrl":21,"./app/controllers/SettingsPersonalCtrl":22,"./app/controllers/SettingsSecurityCtrl":23,"./app/controllers/SigninCtrl":24,"./app/controllers/SignupCtrl":25,"./app/controllers/TickerCtrl":26,"./app/controllers/WithdrawalCtrl":27,"./app/directives/sxAmount":28,"./app/directives/sxAutofocus":29,"./app/directives/sxAvatar":30,"./app/directives/sxBurger":31,"./app/directives/sxCaptcha":32,"./app/directives/sxMenu":33,"./app/directives/sxNiceScroll":34,"./app/directives/sxStyler":35,"./app/directives/sxSubmit":36,"./app/filters/cutter":37,"./app/filters/declension":38,"./app/filters/inArray":39,"./app/filters/limiter":40,"./app/filters/money":41,"./app/filters/prefix":42,"./app/filters/suffix":43,"./app/filters/telegram":44,"./app/modules/clipboard":61,"./app/modules/modal":62,"./app/modules/postfix":63,"./app/modules/storage":64,"./app/modules/toast":65,"./app/run":66}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Config;
Config.$inject = ["$interpolateProvider", "$httpProvider", "$compileProvider", "$qProvider"];

/* @ngInject */
function Config($interpolateProvider, $httpProvider, $compileProvider, $qProvider) {
  $interpolateProvider.startSymbol('[[');
  $interpolateProvider.endSymbol(']]');
  /* @ngInject */

  $httpProvider.interceptors.push(
  /* @ngInject */
  ["$q", "$rootScope", "$toast", function ($q, $rootScope, $toast) {
    return {
      // Called before send a new XHR request.
      request: function request(config) {
        if (config.method === 'POST') {
          var csrf_token = $rootScope.global.csrf_token;

          if (config.data !== undefined) {
            config.data.csrf_token = csrf_token;
          } else {
            config.data = {
              csrf_token: csrf_token
            };
          }

          $rootScope.busy = true;
        }

        return config || $q.when(config);
      },
      // Called when another request fails.
      requestError: function requestError(rejection) {
        $rootScope.busy = false;
        return $q.reject(rejection);
      },
      // Called before a promise is resolved.
      response: function response(resp) {
        if (resp.config.method === 'POST') {
          $rootScope.busy = false;

          if (resp.data && resp.data.type) {
            $toast.show(resp.data.type, resp.data.text);

            if (resp.data.type === 'error') {
              return $q.reject(resp);
            }
          }
        }

        return resp || $q.when(resp);
      },
      // Called when another XHR request returns with an error status code.
      responseError: function responseError(rejection) {
        var statusCode = rejection.status;
        $rootScope.busy = false;

        if (statusCode === 400) {
          $toast.error('<b>Error 400</b> Bad request');
        } else if (statusCode === 401) {
          return location.href = '/signin';
        } else if (statusCode === 404) {
          $toast.error('<b>Error 404</b> The requested URL was not found');
        } else if (statusCode === 500) {
          $toast.error('<b>Error 500</b> Internal Server Error');
        } else {
          $toast.error('<b>Error ' + statusCode + ':</b> ' + rejection.statusText);
        }

        return $q.reject(rejection);
      }
    };
  }]);
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto|skype|tel|tg|bitcoin|litecoin|ethereum):/);
  $qProvider.errorOnUnhandledRejections(false);

  if (typeof $.fn.select2 === 'function') {
    angular.extend($.fn.select2.defaults.defaults, {
      //width:'auto',
      //dropdownAutoWidth:true,
      minimumResultsForSearch: -1,
      allowHtml: true //dropdownCssClass:'select2_custom-dropdown animated fadeIn notranslate',
      //containerCssClass:'select2_custom-container notranslate',

    });
  }
}

;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AllNewsCtrl;
AllNewsCtrl.$inject = ["$http"];

/* @ngInject */
function AllNewsCtrl($http) {
  var vm = this;
  vm.ready = false;
  vm.limit = 10;
  vm.news = null;

  vm.loadMore = function (count) {
    return vm.limit += count;
  };

  vm.link = function (id) {
    return location.href = '/news/' + id;
  };

  function getNews() {
    var query = $http.post('/api', {
      action: 'getNews'
    });
    query.then(function (res) {
      vm.news = res.data;
    });
    return query;
  }

  function onInit() {
    getNews().then(function () {
      vm.ready = true;
    });
  }

  onInit();
}

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ContactsCtrl;
ContactsCtrl.$inject = ["PageData", "$http", "$rootScope"];

/* @ngInject */
function ContactsCtrl(PageData, $http, $rootScope) {
  var vm = this;
  vm.form = null;
  vm.data = PageData.user || {};
  vm.data.action = 'doContacts';
  vm.data.captcha = null;

  vm.submit = function () {
    if (vm.form.$invalid) return false;
    var query = $http.post('/api', vm.data);
    query.then(function (res) {
      if (res.data.type === 'success') {
        vm.data.subject = null;
        vm.data.message = null;
        vm.form.$setPristine();
        $rootScope.$broadcast('FormReset');
        resetCaptcha();
      }
    });
    query.catch(function () {
      return resetCaptcha();
    });
  };

  function resetCaptcha() {
    $rootScope.$broadcast('CaptchaReset');
  }

  function onInit() {}

  onInit();
}

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DashCtrl;
DashCtrl.$inject = ["PageData", "$scope", "$http"];

/* @ngInject */
function DashCtrl(PageData, $scope, $http) {
  var vm = this,
      initialCurrency = $scope.getCurrency();
  vm.ready = false;
  vm.balances = PageData.balances;
  vm.currencies = PageData.currencies;
  vm.currency = findCurrency(initialCurrency);
  vm.history = null;
  vm.sci = null;
  vm.data = {
    action: 'doRefill',
    system: vm.currency.systems[0].code
  };
  $scope.$on('CurrencyChange', function (event, code) {
    if (vm.data.currency === code) return;
    vm.currency = findCurrency(code);
    vm.data.system = vm.currency.systems[0].code;
  });

  vm.setSystem = function (system) {
    if (vm.data.system === system.code) return;
    vm.data.system = system.code;
  };

  vm.refill = function (code) {
    if (!vm.sci || vm.sci && code !== vm.currency.code) {
      vm.sci = null;
      $scope.setCurrency(code);
      vm.submit();
      focusForm();
    }
  };

  vm.submit = function () {
    var query = $http.post(ACCOUNT_API, vm.data);
    query.then(function (res) {
      vm.sci = res.data.sci;
      focusForm();
    });
    query.catch(function () {
      vm.sci = null;
    });
  };

  vm.reset = function () {
    vm.sci = null;
    focusForm();
  };

  function getHistory() {
    var query = $http.post(ACCOUNT_API, {
      action: 'getHistory',
      limit: 3
    });
    query.then(function (res) {
      vm.history = res.data;
      vm.ready = true;
    });
    return query;
  }

  function initBalanceSwiper() {
    new Swiper('.cab-bal .swiper-container', {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 17,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true
      },
      breakpoints: {
        1224: {
          slidesPerView: 5
        },
        992: {
          slidesPerView: 4
        },
        768: {
          slidesPerView: 3
        },
        480: {
          slidesPerView: 2
        }
      }
    });
  }

  function findCurrency(code) {
    return vm.currencies.find(function (subj) {
      return subj.code === code;
    }) || vm.currencies[0];
  }

  function focusForm() {
    $scope.scrollTo('form', 200, 80);
  }

  function onInit() {
    var usdt = findCurrency('usdt'),
        order = ['usdt.trc20', 'usdt.bep20', 'usdt.erc20'];

    if (usdt) {
      usdt.systems.sort(function (a, b) {
        return order.indexOf(a.code) - order.indexOf(b.code);
      });
    }

    if (vm.currency.code === 'usdt') {
      vm.setSystem(vm.currency.systems[0]);
    }

    getHistory();
    initBalanceSwiper();
  }

  onInit();
}

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DepositCtrl;

var _helpers = require("../helpers");

DepositCtrl.$inject = ["PageData", "$scope", "$timeout", "$http"];

/* @ngInject */
function DepositCtrl(PageData, $scope, $timeout, $http) {
  var vm = this,
      urlParams = (0, _helpers.getUrlParams)(),
      initialCurrency = $scope.getCurrency();
  vm.form = null;
  vm.offers = PageData;
  vm.offer = findOffer(initialCurrency);
  vm.plan = vm.offer.plans[0];
  vm.sxAmount = getSxAmount();
  vm.cCode = vm.offer.code.toUpperCase();
  vm.compound = [0, 25, 50, 100];
  vm.data = {
    action: 'doDeposit',
    amount: vm.sxAmount.min,
    plan_id: vm.plan.id,
    compound: vm.compound[0]
  };
  vm.calc = {
    dailyRate: 0,
    dailyProfit: 0,
    totalRate: 0,
    totalProfit: 0,
    rest: 0
  };
  $scope.$on('CurrencyChange', function (event, code) {
    if (vm.offer.code === code) return;
    vm.offer = findOffer(code);
    vm.cCode = code.toUpperCase();
    vm.setPlan(vm.offer.plans[0], true);
  });

  vm.setPlan = function (plan) {
    var reset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (plan.id === vm.plan.id) return;
    vm.plan = plan;
    vm.sxAmount = getSxAmount();

    if (reset === true) {
      vm.data.amount = vm.sxAmount.min;
    }

    vm.data.plan_id = vm.plan.id;
    vm.calculate();
  };

  vm.setCompound = function (compound) {
    compound = parseInt(compound);
    if (compound < 0 || compound > 100 || vm.data.compound === compound) return;
    vm.data.compound = compound;
    vm.calculate();
  };

  vm.calculate = function () {
    $timeout(function () {
      var _vm$sxAmount = vm.sxAmount,
          min = _vm$sxAmount.min,
          max = _vm$sxAmount.max,
          decimals = _vm$sxAmount.decimals,
          amount = (0, _helpers.actualAmount)(vm.data.amount, min, max),
          plan = vm.plan,
          compound = vm.data.compound;
      var dailyProfit = parseFloat(String(amount * plan.rate / 100)),
          totalRate = parseFloat(String(plan.rate * plan.term)),
          rest = parseFloat(String(vm.offer.balance - amount));
      var totalProfit = parseFloat(String(dailyProfit * plan.term));

      if (compound > 0) {
        var iAmount = amount,
            iProfit = 0;

        for (var i = 0; i < plan.term; i++) {
          var nDailyProfit = parseFloat(String(iAmount * plan.rate / 100)),
              nCompound = parseFloat(String(nDailyProfit * compound / 100)),
              nRemain = parseFloat(String(nDailyProfit - nCompound));
          /*console.log(
             moneyFormat(iAmount, 6) + ' -> ' + moneyFormat(nDailyProfit, 6) + ' -> ' + moneyFormat(nCompound, 6) + ' -> ' + moneyFormat(nRemain, 6)
          );*/

          iAmount += nCompound;
          iProfit += nRemain;
        }

        totalProfit = parseFloat(String(iAmount + iProfit));
      }

      vm.calc.dailyRate = (0, _helpers.moneyFormat)(plan.rate, 4);
      vm.calc.dailyProfit = (0, _helpers.moneyFormat)(dailyProfit, decimals);
      vm.calc.totalRate = (0, _helpers.moneyFormat)(totalRate, 4);
      vm.calc.totalProfit = compound > 0 ? (0, _helpers.moneyFormat)(totalProfit, decimals) : (0, _helpers.moneyFormat)(totalProfit + amount, decimals);
      vm.calc.rest = (0, _helpers.moneyFormat)(rest, decimals);
    }, 20);
  };

  vm.submit = function () {
    if (vm.form.$invalid || $scope.busy || vm.calc.rest < 0) return false;
    var query = $http.post(ACCOUNT_API, vm.data);
    query.then(function (res) {
      if (res.data.url !== undefined) {
        return location.href = '/account/deposits';
      }
    });
  };

  function getSxAmount() {
    var cCode = vm.offer.code.toLowerCase();
    return {
      min: vm.plan.min,
      max: vm.plan.max,
      decimals: (0, _helpers.decimalsNum)(cCode)
    };
  }

  function findOffer(code) {
    return vm.offers.find(function (subj) {
      return subj.code.toLowerCase() === code.toLowerCase();
    }) || vm.offers[0];
  }

  function onInit() {
    vm.calculate();

    if (urlParams.has('gid')) {
      var gid = parseInt(urlParams.get('gid')),
          offer = vm.offers.find(function (subj) {
        return subj.id === gid;
      });

      if (offer && offer.code !== vm.offer.code) {
        $scope.setCurrency(offer.code);
      }
    }
  }

  onInit();
}

},{"../helpers":45}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DepositStatsCtrl;
DepositStatsCtrl.$inject = ["DepositStats", "$scope"];

/* @ngInject */
function DepositStatsCtrl(DepositStats, $scope) {
  var vm = this;
  vm.stats = DepositStats;
  vm.cCode = $scope.getCurrency();
  $scope.$on('CurrencyChange', function (event, code) {
    vm.cCode = code;
  });
}

},{}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DepositsCtrl;
DepositsCtrl.$inject = ["PageData", "$scope", "$http", "$timeout"];

/* @ngInject */
function DepositsCtrl(PageData, $scope, $http, $timeout) {
  var vm = this,
      initialLimit = 10,
      initialFilter = {
    status: '',
    currency: {
      code: ''
    }
  };
  vm.ready = false;
  vm.deposits = [];
  vm.limit = initialLimit;
  vm.filters = PageData.filters;
  vm.filter = angular.copy(initialFilter);
  $scope.$watch('vm.filter', function () {
    vm.limit = initialLimit;
  }, true);

  vm.loadMore = function (count) {
    return vm.limit += parseInt(count);
  };

  vm.resetFilter = function () {
    vm.filter = angular.copy(initialFilter);
    $timeout(function () {
      $('select').trigger('refresh');
    }, 15);
  };

  function getDeposits() {
    return $http.post(ACCOUNT_API, {
      action: 'getDeposits'
    }).then(function (res) {
      vm.deposits = res.data;
    });
  }

  function onInit() {
    getDeposits().then(function () {
      vm.ready = true;
    });
  }

  onInit();
}

},{}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FaqCtrl;

var _helpers = require("../helpers");

FaqCtrl.$inject = ["$http"];

/* @ngInject */
function FaqCtrl($http) {
  var vm = this,
      urlParams = (0, _helpers.getUrlParams)();
  vm.ready = false;
  vm.faq = null;

  function getFaq() {
    var query = $http.post('/api', {
      action: 'getFaq'
    });
    query.then(function (res) {
      var id = urlParams.get('section') || 1;
      id = parseInt(id);

      if (isNaN(id) || id < 1 || id > 4) {
        id = 1;
      }

      vm.faq = res.data.find(function (subj) {
        return subj.id === id;
      }) || res.data[0];
    });
    return query;
  }

  function onInit() {
    getFaq().then(function () {
      vm.ready = true;
      angular.element("#media-faq-".concat(vm.faq.id)).addClass('active');
    });
  }

  onInit();
}

},{"../helpers":45}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HistoryCtrl;
HistoryCtrl.$inject = ["PageData", "$scope", "$timeout", "$http"];

/* @ngInject */
function HistoryCtrl(PageData, $scope, $timeout, $http) {
  var vm = this,
      initialLimit = 10,
      initialFilter = {
    type_label: '',
    currency: {
      code: ''
    },
    status: ''
  };
  vm.ready = false;
  vm.history = [];
  vm.limit = initialLimit;
  vm.filters = PageData.filters;
  vm.filter = angular.copy(initialFilter);
  $scope.$watch('vm.filter', function () {
    vm.limit = initialLimit;
  }, true);

  vm.loadMore = function (count) {
    return vm.limit += parseInt(count);
  };

  vm.resetFilter = function () {
    vm.filter = angular.copy(initialFilter);
    $timeout(function () {
      $('select').trigger('refresh');
    }, 15);
  };

  function getHistory() {
    return $http.post(ACCOUNT_API, {
      action: 'getHistory'
    }).then(function (res) {
      vm.history = res.data;
    });
  }

  function onInit() {
    getHistory().then(function () {
      vm.ready = true;
    });
  }

  onInit();
}

},{}],11:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HomeCtrl;
HomeCtrl.$inject = ["$http", "$timeout"];

/* @ngInject */
function HomeCtrl($http, $timeout) {
  var vm = this;
  vm.ready = false;
  vm.offer = [];

  function getOffer() {
    var query = $http.post('/api', {
      action: 'getOffer',
      group: true
    });
    query.then(function (res) {
      vm.offer = res.data;
    });
    return query;
  }

  function initParallax() {
    var scene = document.getElementById('header-scene');

    if (scene) {
      new Parallax(scene);
    }
  }

  function initSwiper() {
    new Swiper('.stak-slider .swiper-container', {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 24,
      freeMode: true,
      watchOverflow: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true
      },
      navigation: {
        nextEl: '.stak-nav .swiper-button-next',
        prevEl: '.stak-nav .swiper-button-prev'
      },
      breakpoints: {
        1480: {
          slidesPerView: 5,
          watchOverflow: true
        },
        1224: {
          slidesPerView: 4
        },
        992: {
          slidesPerView: 3
        },
        600: {
          slidesPerView: 2
        }
      }
    });
  }

  function onInit() {
    getOffer().then(function () {
      vm.ready = true;
      $timeout(function () {
        initSwiper();
      }, 15);
    });
    $timeout(function () {
      initParallax();
    });
  }

  onInit();
}

},{}],12:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LatestDepositsCtrl;
LatestDepositsCtrl.$inject = ["LatestDeposits"];

/* @ngInject */
function LatestDepositsCtrl(LatestDeposits) {
  var vm = this;
  vm.deposits = LatestDeposits;
}

},{}],13:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NewsCtrl;
NewsCtrl.$inject = ["PageData", "$timeout"];

/* @ngInject */
function NewsCtrl(PageData, $timeout) {
  var vm = this;
  vm.news = PageData.news;
  vm.related = PageData.related;

  function onInit() {
    $timeout(function () {
      angular.element('#media-news').addClass('active');
    }, 10);
  }

  onInit();
}

},{}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OfferCtrl;
OfferCtrl.$inject = ["$http"];

/* @ngInject */
function OfferCtrl($http) {
  var vm = this;
  vm.ready = false;
  vm.offer = [];

  function getOffer() {
    var query = $http.post('/api', {
      action: 'getOffer',
      group: true
    });
    query.then(function (res) {
      vm.offer = res.data;
    });
    return query;
  }

  function onInit() {
    getOffer().then(function () {
      vm.ready = true;
    });
  }

  onInit();
}

},{}],15:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PartnersCtrl;
PartnersCtrl.$inject = ["$scope", "$http", "$timeout"];

/* @ngInject */
function PartnersCtrl($scope, $http, $timeout) {
  var vm = this,
      initialLimit = 10,
      initialFilter = {
    filter_query: ''
  };
  vm.ready = false;
  vm.partners = [];
  vm.limit = initialLimit;
  vm.filter = angular.copy(initialFilter);
  $scope.$watch('vm.filter', function () {
    vm.limit = initialLimit;
  }, true);

  vm.loadMore = function (count) {
    return vm.limit += parseInt(count);
  };

  vm.resetFilter = function () {
    vm.filter = angular.copy(initialFilter);
    $timeout(function () {
      $('select').trigger('refresh');
    }, 15);
  };

  function getPartners() {
    return $http.post(ACCOUNT_API, {
      action: 'getPartners'
    }).then(function (res) {
      vm.partners = res.data;
    });
  }

  function initDropdown() {
    $('.js-select').on('click', function () {
      $(this).parents('.cab-profit__val').find('.cab-profit__select').fadeToggle(100).parents('tr').siblings().find('.cab-profit__select').fadeOut(100);
    });
    $(document).on('mouseup', function (e) {
      var jsSelect = $('.cab-profit__select');
      jsSelect.each(function () {
        if (!$(e.target).hasClass('js-select')) {
          $(this).fadeOut(100);
        }
      });
    });
  }

  function onInit() {
    getPartners().then(function () {
      vm.ready = true;
      $timeout(function () {
        initDropdown();
      }, 100);
    });
  }

  onInit();
}

},{}],16:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PartnersHistoryCtrl;
PartnersHistoryCtrl.$inject = ["PageData", "$scope", "$http", "$timeout"];

/* @ngInject */
function PartnersHistoryCtrl(PageData, $scope, $http, $timeout) {
  var vm = this,
      initialLimit = 10,
      initialFilter = {
    filter_query: '',
    currency: {
      code: ''
    }
  };
  vm.ready = false;
  vm.history = [];
  vm.limit = initialLimit;
  vm.filters = PageData.filters;
  vm.filter = angular.copy(initialFilter);
  $scope.$watch('vm.filter', function () {
    vm.limit = initialLimit;
  }, true);

  vm.loadMore = function (count) {
    return vm.limit += parseInt(count);
  };

  vm.resetFilter = function () {
    vm.filter = angular.copy(initialFilter);
    $timeout(function () {
      $('select').trigger('refresh');
    }, 15);
  };

  function getHistory() {
    return $http.post(ACCOUNT_API, {
      action: 'getPartnersHistory'
    }).then(function (res) {
      vm.history = res.data;
    });
  }

  function onInit() {
    getHistory().then(function () {
      vm.ready = true;
    });
  }

  onInit();
}

},{}],17:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PromoCtrl;

var _locale = _interopRequireDefault(require("../locale"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

PromoCtrl.$inject = ["PageData", "$clipboard", "$toast"];

/* @ngInject */
function PromoCtrl(PageData, $clipboard, $toast) {
  var vm = this,
      lang = 'en',
      link = PageData.link,
      baseUrl = window.location.origin,
      sizes = ['1920x200', '1200x150', '728x90', '468x60', '160x600', '300x250', '125x125'];
  vm.packs = [{
    key: 'default',
    name: 'Default',
    ext: '.gif'
  }];
  vm.link = PageData.link;
  vm.pack = vm.packs[0];
  vm.sizes = sizes;

  vm.bannerSrc = function (size) {
    return [baseUrl, 'promo', lang, vm.pack.key, size + vm.pack.ext].join('/');
  };

  vm.embedCode = function (size) {
    return '&lt;a target=&quot;_blank&quot; href=&quot;' + link + '&quot;&gt;&lt;img src=&quot;' + vm.bannerSrc(size) + '&quot; border=&quot;0&quot;&gt;&lt;/a&gt';
  };

  vm.copyEmbed = function (size) {
    var embed = vm.embedCode(size);
    $clipboard.copy(embed).then(function () {
      $toast.info((0, _locale.default)('embedCodeCopied'));
    });
  };

  function onInit() {}

  onInit();
}

},{"../locale":46}],18:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PromoToolsCtrl;

var _locale = _interopRequireDefault(require("../locale"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

PromoToolsCtrl.$inject = ["PageData", "$clipboard", "$toast"];

/* @ngInject */
function PromoToolsCtrl(PageData, $clipboard, $toast) {
  var vm = this,
      link = PageData.link,
      baseUrl = window.location.origin;
  vm.link = PageData.link;

  vm.bannerSrc = function (index) {
    return [baseUrl, 'promo/tools', index + '.gif'].join('/');
  };

  vm.imageSrc = function (index) {
    return [baseUrl, 'promo/tools', index + '.jpg'].join('/');
  };

  vm.embedCode = function (size) {
    return '&lt;a target=&quot;_blank&quot; href=&quot;' + link + '&quot;&gt;&lt;img src=&quot;' + vm.bannerSrc(size) + '&quot; border=&quot;0&quot;&gt;&lt;/a&gt';
  };

  vm.copyEmbed = function (size) {
    var embed = vm.embedCode(size);
    $clipboard.copy(embed).then(function () {
      $toast.info((0, _locale.default)('embedCodeCopied'));
    });
  };

  function onInit() {}

  onInit();
}

},{"../locale":46}],19:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ReferralStatsCtrl;
ReferralStatsCtrl.$inject = ["ReferralStats", "$scope"];

/* @ngInject */
function ReferralStatsCtrl(ReferralStats, $scope) {
  var vm = this;
  vm.stats = ReferralStats;
  vm.cCode = $scope.getCurrency();
  $scope.$on('CurrencyChange', function (event, code) {
    vm.cCode = code;
  });
}

},{}],20:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResetCtrl;
ResetCtrl.$inject = ["$http", "$rootScope"];

/* @ngInject */
function ResetCtrl($http, $rootScope) {
  var vm = this;
  vm.form = null;
  vm.data = {
    action: 'doReset',
    email: null
  };

  vm.submit = function () {
    if (vm.form.$invalid) return false;
    var query = $http.post('/auth', vm.data);
    query.then(function (res) {
      if (res.data.type === 'success') {
        vm.data.email = null;
        vm.form.$setPristine();
        $rootScope.$broadcast('FormReset');
      }

      resetCaptcha();
    });
    query.catch(function () {
      return resetCaptcha();
    });
  };

  function resetCaptcha() {
    $rootScope.$broadcast('CaptchaReset');
  }
}

},{}],21:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SettingsAccountsCtrl;
SettingsAccountsCtrl.$inject = ["PageData", "$http", "$rootScope"];

/* @ngInject */
function SettingsAccountsCtrl(PageData, $http, $rootScope) {
  var vm = this;
  vm.form = null;
  vm.accounts = {};
  vm.data = {};
  angular.forEach(PageData.accounts, function (acc) {
    return vm.accounts[acc.code] = acc;
  });
  angular.forEach(vm.accounts, function (acc) {
    return vm.data[acc.id] = acc.account_name || null;
  });

  vm.submit = function () {
    if (vm.form.$invalid) return false;
    var postData = {
      action: 'doAccountSettings',
      account: vm.data
    };
    var query = $http.post(ACCOUNT_API, postData);
    query.then(function () {
      vm.form.$setPristine();
      $rootScope.$broadcast('FormReset');
    });
    return query;
  };
}

},{}],22:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SettingsPersonalCtrl;
SettingsPersonalCtrl.$inject = ["PageData", "$rootScope", "$http", "$toast", "$scope"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* @ngInject */
function SettingsPersonalCtrl(PageData, $rootScope, $http, $toast, $scope) {
  var vm = this;
  vm.form = null;
  vm.data = _objectSpread({}, PageData.user);
  vm.data.action = 'doPersonalSettings';
  vm.data.telegram = vm.data.telegram ? '@' + vm.data.telegram : null;

  vm.submit = function () {
    if (vm.form.$invalid) return false;
    $http.post(ACCOUNT_API, vm.data).then(function () {
      $rootScope.global.user.username = vm.data.username;
    });
  };

  function initUploader() {
    return new plupload.Uploader({
      runtimes: 'html5',
      url: $rootScope.global.image.uploader,
      container: 'imageContainer',
      browse_button: ['imageBtn1', 'imageBtn2'],
      multi_selection: false,
      multipart_params: {
        action: 'doAvatarUpload',
        csrf_token: $rootScope.global.csrf_token
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      filters: {
        mime_types: [{
          title: 'Images',
          extensions: 'jpg,jpeg,png'
        }],
        max_file_size: '1024kb'
      },
      init: {
        FilesAdded: function FilesAdded(up) {
          up.start();
        },
        FileUploaded: function FileUploaded(up, files, image) {
          vm.data.image = image.response;
          $rootScope.global.user.image = image.response;
          $scope.$apply();
          attachAvatar(vm.data.image);
          $rootScope.$broadcast('AvatarUpdate', image.response);
        },
        Error: function Error(up, error) {
          $toast.error(error.message);
        }
      }
    });
  }

  function attachAvatar(image) {
    return $http.post(ACCOUNT_API, {
      action: 'doAvatarAttach',
      image: image
    });
  }

  function onInit() {
    var uploader = initUploader();
    uploader.init();
  }

  onInit();
}

},{}],23:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SettingsSecurityCtrl;
SettingsSecurityCtrl.$inject = ["PageData", "$http", "$rootScope"];

/* @ngInject */
function SettingsSecurityCtrl(PageData, $http, $rootScope) {
  var vm = this;
  vm.form = null;
  vm.data = {
    action: 'doSecuritySettings',
    password: null,
    new_password: null,
    new_password_confirmation: null
  };

  vm.submit = function () {
    if (vm.form.$invalid) return false;
    var fetch = $http.post(ACCOUNT_API, vm.data);
    fetch.then(function () {
      vm.data.password = null;
      vm.data.new_password = null;
      vm.data.new_password_confirmation = null;
      vm.form.$setPristine();
      $rootScope.$broadcast('FormReset');
    });
    fetch.catch(function () {
      vm.form.$setPristine();
      $rootScope.$broadcast('FormReset');
    });
  };
}

},{}],24:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SigninCtrl;
SigninCtrl.$inject = ["$http", "$rootScope"];

/* @ngInject */
function SigninCtrl($http, $rootScope) {
  var vm = this;
  vm.form = null;
  vm.data = {
    action: 'doSignin',
    email: null,
    password: null,
    pincode: null
  };

  vm.submit = function () {
    if (vm.form.$invalid) return false;
    var query = $http.post('/auth', vm.data);
    query.then(function (res) {
      if (res.data.uri !== undefined) {
        return location.href = res.data.uri;
      }

      resetCaptcha();
    });
    query.catch(function () {
      return resetCaptcha();
    });
  };

  function resetCaptcha() {
    $rootScope.$broadcast('CaptchaReset');
  }
}

},{}],25:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SignupCtrl;
SignupCtrl.$inject = ["$http", "$rootScope"];

/* @ngInject */
function SignupCtrl($http, $rootScope) {
  var vm = this;
  vm.step = 1;
  vm.form = null;
  vm.upline = null;
  vm.signupData = null;
  vm.data = {
    action: 'doSignup',
    email: null,
    password: null,
    captcha: null
  };

  vm.submit = function () {
    if (vm.form.$invalid) return false;
    var query = $http.post('/auth', vm.data);
    query.then(function (res) {
      var signupData = res.data.data;

      if (signupData.email !== undefined) {
        vm.signupData = signupData;
        vm.step = 2;
        $rootScope.global.is.logged = true;
      } else {
        vm.data.email = null;
        vm.data.password = null;
      }

      $rootScope.$broadcast('FormReset');
      resetCaptcha();
    });
    query.catch(function () {
      return resetCaptcha();
    });
  };

  function getUpline() {
    var query = $http.post('/auth', {
      action: 'getUpline'
    });
    query.then(function (res) {
      return vm.upline = res.data;
    });
    return query;
  }

  function resetCaptcha() {
    $rootScope.$broadcast('CaptchaReset');
  }

  function onInit() {
    getUpline();
  }

  onInit();
}

},{}],26:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TickerCtrl;
TickerCtrl.$inject = ["$http"];

/* @ngInject */
function TickerCtrl($http) {
  var vm = this,
      coins = ['usdt', 'btc', 'eth', 'bnb', 'sol', 'trx'];
  vm.ready = false;
  vm.ticker = null;

  function getTicker() {
    var query = $http.post('/api', {
      action: 'getTicker'
    });
    query.then(function (res) {
      var ticker = [];
      coins.forEach(function (code) {
        var tick = res.data.find(function (subj) {
          return subj.symbol === code;
        });

        if (tick) {
          if (tick.symbol === 'bnb') {
            tick.name = 'Binance Coin';
          }

          ticker.push(tick);
        }
      });
      vm.ticker = ticker;
    });
    return query;
  }

  function onInit() {
    getTicker().then(function () {
      vm.ready = true;
    });
  }

  onInit();
}

},{}],27:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = WithdrawalCtrl;

var _helpers = require("../helpers");

WithdrawalCtrl.$inject = ["PageData", "$scope", "$timeout", "$http"];

/* @ngInject */
function WithdrawalCtrl(PageData, $scope, $timeout, $http) {
  var vm = this,
      urlParams = (0, _helpers.getUrlParams)(),
      initialCurrency = $scope.getCurrency();
  vm.form = null;
  vm.balances = PageData.balances;
  vm.balance = findBalance(initialCurrency);
  vm.system = vm.balance.accounts[0];
  vm.sxAmount = getSxAmount();
  vm.cCode = vm.balance.currency.code.toUpperCase();
  vm.data = {
    action: 'doWithdrawal',
    amount: vm.balance.amount,
    system: vm.system.code,
    account: vm.system.number,
    pincode: null
  };
  vm.calc = {
    rest: 0
  };
  $scope.$on('CurrencyChange', function (event, code) {
    if (code === vm.cCode) return;
    vm.balance = findBalance(code);
    vm.cCode = code.toUpperCase();
    vm.setSystem(vm.balance.accounts[0]);
  });

  vm.setSystem = function (system) {
    if (system.code === vm.system.code) return;
    vm.system = system;
    vm.sxAmount = getSxAmount();
    vm.data.amount = vm.balance.amount;
    vm.data.system = vm.system.code;
    vm.data.account = vm.system.number;
    vm.calculate();
  };

  vm.calculate = function () {
    $timeout(function () {
      var _vm$sxAmount = vm.sxAmount,
          min = _vm$sxAmount.min,
          max = _vm$sxAmount.max,
          decimals = _vm$sxAmount.decimals,
          amount = (0, _helpers.actualAmount)(vm.data.amount, min, max);
      var rest = parseFloat(String(vm.balance.amount - amount));
      vm.calc.rest = (0, _helpers.moneyFormat)(rest, decimals);
    }, 20);
  };

  vm.submit = function () {
    if (vm.form.$invalid || $scope.busy || vm.calc.rest < 0) return false;
    var query = $http.post(ACCOUNT_API, vm.data);
    query.then(function (res) {
      var data = res.data.data,
          balance = findBalance(data.code);
      balance.amount = data.balance;
      vm.data.amount = data.balance;
      vm.data.pincode = null;
      vm.calculate();
      vm.form.$setPristine();
      $scope.$broadcast('FormReset');
    });
  };

  function getSxAmount() {
    var cCode = vm.balance.currency.code.toLowerCase();
    return {
      min: vm.system.min,
      max: 1000000,
      decimals: (0, _helpers.decimalsNum)(cCode)
    };
  }

  function findBalance(code) {
    return vm.balances.find(function (subj) {
      return subj.currency.code === code;
    }) || vm.balances[0];
  }

  function onInit() {
    var usdt = findBalance('usdt'),
        order = ['usdt.trc20', 'usdt.bep20', 'usdt.erc20'];

    if (usdt) {
      usdt.accounts.sort(function (a, b) {
        return order.indexOf(a.code) - order.indexOf(b.code);
      });
    }

    if (vm.balance.currency.code === 'usdt') {
      vm.setSystem(vm.balance.accounts[0]);
    }

    vm.calculate();

    if (urlParams.has('curr')) {
      var curr = urlParams.get('curr').toLowerCase(),
          balance = findBalance(curr);

      if (balance.code !== vm.balance.currency.code) {
        $scope.setCurrency(curr);
      }
    }
  }

  onInit();
}

},{"../helpers":45}],28:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sxAmount;
sxAmount.$inject = ["$timeout"];

/* @ngInject */
function sxAmount($timeout) {
  return {
    restrict: 'A',
    scope: {
      model: '=ngModel',
      params: '=sxAmount',
      onchange: '&'
    },
    link: function link(scope, elem) {
      elem.bind('blur paste', function () {
        amountFormat();
      });
      scope.$watch('params', function () {
        amountFormat();
      }, true);

      function amountFormat() {
        $timeout(function () {
          var currency = scope.$root.getCurrency() || 'usd';

          if (!angular.isObject(scope.params)) {
            scope.params = {};
          }

          var value = scope.model || 0,
              min = parseFloat(scope.params.min) || 0,
              max = parseFloat(scope.params.max) || 1000000,
              decimals = currency === 'usd' || currency === 'rub' ? 2 : 6;

          if (scope.params.decimals !== undefined && !isNaN(scope.params.decimals)) {
            decimals = parseInt(scope.params.decimals);
          }

          value = parseFloat(value.toString().replace(',', '.'));

          if (isNaN(value) || value < min) {
            value = min;
          }

          if (max && value > max) {
            value = max;
          }

          scope.model = parseFloat(value.toFixed(decimals));
          scope.$apply();
          $timeout(function () {
            scope.onchange();
          }, 10);
        }, 10);
      }
    }
  };
}

},{}],29:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sxAutofocus;
sxAutofocus.$inject = ["$timeout"];

/* @ngInject */
function sxAutofocus($timeout) {
  return {
    restrict: 'A',
    link: function link($scope, $element) {
      $timeout(function () {
        $element[0].focus();
      }, 10);
    }
  };
}

},{}],30:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sxAvatar;
sxAvatar.$inject = ["$rootScope"];

/* @ngInject */
function sxAvatar($rootScope) {
  return {
    restrict: 'A',
    link: function link(scope, elem, attr) {
      var noAvatar = '/assets/app/img/logo.svg';

      function setSrc(image) {
        var src = !image ? noAvatar : $rootScope.global.image.cdn + image;
        elem.attr('src', src);
      }

      if (attr.dynamic !== undefined) {
        scope.$on('AvatarUpdate', function (event, image) {
          return setSrc(image);
        });
      }

      setSrc(attr.sxAvatar);
    }
  };
}

},{}],31:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sxBurger;
sxBurger.$inject = ["$window"];

/* @ngInject */
function sxBurger($window) {
  return {
    restrict: 'A',
    link: function link(scope, elem) {
      var $menu = angular.element('.cab-menu');
      elem.on('click', function () {
        if (elem.hasClass('active')) {
          return hideMenu();
        }

        showMenu();
      });

      function showMenu() {
        elem.addClass('active');
        $menu.addClass('active');
        angular.element($window).bind('resize', hideMenuOnDesktop);
      }

      function hideMenu() {
        elem.removeClass('active');
        $menu.removeClass('active');
        angular.element($window).unbind('resize', hideMenuOnDesktop);
      }

      function hideMenuOnDesktop() {
        if ($window.innerWidth >= 1200) {
          hideMenu();
        }
      }
    }
  };
}

;

},{}],32:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sxCaptcha;

var _helpers = require("../helpers");

sxCaptcha.$inject = ["$rootScope", "$timeout"];

/* @ngInject */
function sxCaptcha($rootScope, $timeout) {
  return {
    restrict: 'EA',
    replace: true,
    template: '<input type="hidden" ng-required="true">',
    scope: {
      model: '=ngModel',
      params: '=params'
    },
    link: function link(scope, elem) {
      if ($rootScope.global.captcha === false) return false;
      var defaults = {
        sitekey: $rootScope.global.captcha,
        callback: function callback(res) {
          scope.model = res;
          scope.$apply();
        }
      };
      var params = angular.extend(defaults, scope.params);
      scope.$on('CaptchaReset', function () {
        grecaptcha.ready(function () {
          grecaptcha.reset();
          scope.model = null;
          scope.$apply();
        });
      });

      function initCaptcha() {
        grecaptcha.ready(function () {
          var uniqueId = 'reCaptcha_' + (0, _helpers.getRandomInteger)(6),
              div = angular.element('<div id="' + uniqueId + '" class="reCaptcha_widget"></div>');
          elem.after(div);
          grecaptcha.render(uniqueId, params);
        });
      }

      $timeout(function () {
        initCaptcha();
      }, 15);
    }
  };
}

;

},{"../helpers":45}],33:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sxMenu;
sxMenu.$inject = ["$rootScope"];

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/* @ngInject */
function sxMenu($rootScope) {
  return {
    restrict: 'A',
    link: function link(scope, elem, attr) {
      var links = elem.find('a'),
          className = attr.sxMenu || 'active',
          url = $rootScope.global.url;

      var _iterator = _createForOfIteratorHelper(links),
          _step;

      try {
        var _loop = function _loop() {
          var link = _step.value;
          link = angular.element(link);
          var parent = link.parent('li');

          if (link.attr('href') === url) {
            addClassName(link, parent, className);
          }

          var dataLinks = link.data('links');

          if (dataLinks !== undefined) {
            dataLinks = dataLinks.trim().split(', ');
            dataLinks.forEach(function (value) {
              if (url === "/".concat(value)) {
                addClassName(link, parent, className);
              }
            });
          }
        };

        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      function addClassName(link, parent) {
        var className = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : className;

        if (parent.length) {
          parent.addClass(className);
        } else {
          link.addClass(className);
        }
      }
    }
  };
}

;

},{}],34:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sxNiceScroll;
sxNiceScroll.$inject = ["$timeout"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* @ngInject */
function sxNiceScroll($timeout) {
  return {
    restrict: 'A',
    scope: {
      params: '=sxNiceScroll'
    },
    link: function link(scope, elem) {
      var defaults = {
        cursorcolor: '#092ABB',
        cursorwidth: '0px',
        cursorborder: '0px solid #fff',
        zindex: 20,
        emulatetouch: true,
        autohidemode: false,
        cursorborderradius: '0px',
        railalign: 'right'
      };

      var params = _objectSpread(_objectSpread({}, defaults), scope.params);

      $timeout(function () {
        elem.niceScroll('.wrap', params);
      }, 30);
    }
  };
}

},{}],35:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sxStyler;
sxStyler.$inject = ["$timeout"];

/* @ngInject */
function sxStyler($timeout) {
  return {
    restrict: 'A',
    scope: {
      params: '=sxStyler'
    },
    link: function link(scope, elem) {
      $timeout(function () {
        elem.styler();
      }, 15);
    }
  };
}

},{}],36:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sxSubmit;

var _locale = _interopRequireDefault(require("../locale"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

sxSubmit.$inject = ["$rootScope", "$toast", "$timeout"];

/* @ngInject */
function sxSubmit($rootScope, $toast, $timeout) {
  return {
    restrict: 'A',
    require: '^form',
    link: function link(scope, elem, attr, ctrl) {
      elem.bind('click', function () {
        if (ctrl.$invalid) {
          var firstControl = false;
          ctrl.$$controls.forEach(function (control) {
            if (control.$invalid) {
              control.$setTouched();

              if (!firstControl) {
                firstControl = control;
                $timeout(function () {
                  control.$$element.focus();
                }, 10);
              }
            }
          });
          $toast.error((0, _locale.default)('validationError'));
          return false;
        }
      });
      scope.$on('FormReset', function () {
        $timeout(function () {
          ctrl.$$controls.forEach(function (control) {
            if (control.$invalid) {
              control.$setUntouched();
            }
          });
        }, 10);
      });
    }
  };
}

},{"../locale":46}],37:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = cutterFilter;

function cutterFilter() {
  return function (text) {
    var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 12;
    if (!text) return null;
    text = text.toString();

    if (text.length / 2 > length) {
      var tmp = text.slice(0, length);
      tmp += '...';
      tmp += text.slice(-length);
      return tmp;
    }

    return text;
  };
}

},{}],38:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = declensionFilter;

var _locale = _interopRequireDefault(require("../locale"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function declensionFilter() {
  return function (number, declension) {
    var inline = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    declension = (0, _locale.default)(declension).split('.');
    var num = Math.abs(number);
    num %= 100;

    if (num >= 5 && num <= 20) {
      return translate(2);
    }

    num %= 10;

    if (num === 1) {
      return translate(0);
    }

    if (num >= 2 && num <= 4) {
      return translate(1);
    }

    return translate(2);

    function translate(key) {
      var string = '';

      if (inline) {
        string = number + ' ';
      }

      return string + declension[key];
    }
  };
}

},{"../locale":46}],39:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = inArrayFilter;

function inArrayFilter() {
  return function (needle, haystack) {
    return haystack !== undefined && haystack.length > 0 && haystack.indexOf(needle) !== -1;
  };
}

},{}],40:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = limiterFilter;

function limiterFilter() {
  return function (value, max, wordwise, tail) {
    if (!value) return '';
    max = parseInt(max) || 10;
    if (!max || value.length <= max) return value;
    value = value.substr(0, max);

    if (wordwise) {
      var lastspace = value.lastIndexOf(' ');

      if (lastspace !== -1) {
        if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',') {
          lastspace = lastspace - 1;
        }

        value = value.substr(0, lastspace);
      }
    }

    return value + (tail || '...');
  };
}

},{}],41:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = moneyFilter;

/* @ngInject */
function moneyFilter() {
  return function (amount) {
    var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 6;
    amount = parseFloat(amount);

    if (isNaN(amount) || amount < 0) {
      amount = 0;
    }

    return parseFloat(amount.toFixed(decimals)).toString();
  };
}

},{}],42:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = prefixFilter;

function prefixFilter() {
  return function (text, prefix) {
    return (prefix || null) + text;
  };
}

},{}],43:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = suffixFilter;

function suffixFilter() {
  return function (text, suffix) {
    return text + (suffix || null);
  };
}

},{}],44:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = telegramFilter;

function telegramFilter() {
  return function (value) {
    return value.replace('@', '');
  };
}

},{}],45:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actualAmount = actualAmount;
exports.arrayChunk = arrayChunk;
exports.arrayLast = arrayLast;
exports.decimalsNum = decimalsNum;
exports.emptyArray = emptyArray;
exports.getLocalStorageWithExpiry = getLocalStorageWithExpiry;
exports.getRandomInteger = getRandomInteger;
exports.getRandomIntegerBetween = getRandomIntegerBetween;
exports.getRandomString = getRandomString;
exports.getUrlParams = getUrlParams;
exports.inArray = inArray;
exports.moneyFormat = moneyFormat;
exports.onlyUnique = onlyUnique;
exports.parseAmount = parseAmount;
exports.setLocalStorageWithExpiry = setLocalStorageWithExpiry;
exports.translateInit = translateInit;

function getRandomInteger() {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 9;
  var charset = '1234567890';
  var randomString = '';

  for (var i = 0; i < length; i++) {
    var randomPoz = Math.floor(Math.random() * charset.length);
    randomString += charset.substring(randomPoz, randomPoz + 1);
  }

  return parseInt(randomString);
}

function getRandomIntegerBetween(min, max) {
  min = min || 1000000;
  max = max || 9999999;
  return parseInt(Math.random() * (max - min) + min);
}

function getRandomString(length) {
  var charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var randomString = '';

  for (var i = 0; i < length; i++) {
    var randomPoz = Math.floor(Math.random() * charset.length);
    randomString += charset.substring(randomPoz, randomPoz + 1);
  }

  return randomString;
}

function moneyFormat(amount, decimals) {
  decimals = parseFloat(decimals) || 6;
  return parseFloat(amount.toFixed(decimals));
}

function arrayChunk(array, size) {
  var chunked_arr = [];

  for (var i = 0; i < array.length; i++) {
    var last = chunked_arr[chunked_arr.length - 1];

    if (!last || last.length === size) {
      chunked_arr.push([array[i]]);
    } else {
      last.push(array[i]);
    }
  }

  return chunked_arr;
}

function arrayLast(array) {
  if (!array instanceof Array) return false;
  if (array.length === 1) return array[0];
  return array[array.length - 1];
}

function inArray(needle, haystack) {
  return haystack !== undefined && haystack.length > 0 && haystack.indexOf(needle) !== -1;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function translateInit() {
  var pageLanguage = $('html').attr('lang');
  new google.translate.TranslateElement({
    pageLanguage: pageLanguage
  });
}

function decimalsNum() {
  var code = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'usd';
  return inArray(code, ['usd', 'rub']) ? 2 : 6;
}

function getUrlParams() {
  var paramsString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.search;
  return new URLSearchParams(paramsString);
}

function emptyArray() {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
  return Array.from(Array(length).keys());
}

function parseAmount(amount) {
  amount = amount || 0;
  return parseFloat(amount.toString().replace(',', '.'));
}

function actualAmount(amount) {
  var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000000;
  amount = parseAmount(amount);

  if (isNaN(amount) || amount < min) {
    amount = min;
  }

  if (amount > max) {
    amount = max;
  }

  return parseFloat(amount);
}

function setLocalStorageWithExpiry(key, value, ttl) {
  var now = new Date();
  var item = {
    value: value,
    expiry: now.getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getLocalStorageWithExpiry(key) {
  var itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  var item = JSON.parse(itemStr);
  var now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

},{}],46:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = __;

var _en = require("./locale/en");

var _cn = require("./locale/cn");

var _ru = require("./locale/ru");

var _ko = require("./locale/ko");

var _es = require("./locale/es");

var _de = require("./locale/de");

var _it = require("./locale/it");

var _fr = require("./locale/fr");

var _mn = require("./locale/mn");

var _pt = require("./locale/pt");

var _vi = require("./locale/vi");

var _ja = require("./locale/ja");

var _in = require("./locale/in");

var _tr = require("./locale/tr");

var msgs = {
  en: _en.locale_en,
  cn: _cn.locale_cn,
  ru: _ru.locale_ru,
  ko: _ko.locale_ko,
  es: _es.locale_es,
  de: _de.locale_de,
  it: _it.locale_it,
  fr: _fr.locale_fr,
  mn: _mn.locale_mn,
  pt: _pt.locale_pt,
  vi: _vi.locale_vi,
  ja: _ja.locale_ja,
  in: _in.locale_in,
  tr: _tr.locale_tr
};

function __(key) {
  var lang = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.LANG;
  if (!msgs[lang]) return key;
  var locale = msgs[lang];
  var keyParts = key.split('.');

  if (keyParts.length === 1) {
    return locale[key] && typeof locale[key] === 'string' ? locale[key] : key;
  }

  keyParts.forEach(function (value) {
    locale = locale[value] || key;
  });
  return locale;
}

},{"./locale/cn":47,"./locale/de":48,"./locale/en":49,"./locale/es":50,"./locale/fr":51,"./locale/in":52,"./locale/it":53,"./locale/ja":54,"./locale/ko":55,"./locale/mn":56,"./locale/pt":57,"./locale/ru":58,"./locale/tr":59,"./locale/vi":60}],47:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_cn = void 0;
var locale_cn = {
  referralLinkCopied: '引荐链接已成功复制到剪贴板',
  embedCodeCopied: '嵌入代码已复制到剪贴板',
  accountNumberCopied: '帐号已复制到剪贴板',
  depositCloseConfirmation: '您确定要关闭这笔存款吗？',
  validationError: '请检查填写表格字段的正确性',
  chartEmpty: '没有数据显示',
  chartBalloon: {
    deposited: '存入',
    withdrawals: '提款',
    profit: '利润',
    referrals: '推荐人'
  },
  days_decl: '天.天.天',
  weeks_decl: '周.周.周',
  toast: {
    title: ['成功', '错误', '警告', '信息'],
    close: '关闭'
  },
  chartDepositBalloon: {
    date: '日期',
    interest: '兴趣',
    profit: '利润'
  },
  calcChart: {
    depositAmount: '订金',
    netProfit: '净利',
    totalProfit: '总利润'
  },
  month: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
  pincodeCopied: '付款密码已成功复制',
  walletAddressCopied: '钱包地址复制成功'
};
exports.locale_cn = locale_cn;

},{}],48:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_de = void 0;
var locale_de = {
  referralLinkCopied: 'Empfehlungslink erfolgreich in die Zwischenablage kopiert',
  embedCodeCopied: 'Der Einbettungscode wird in die Zwischenablage kopiert',
  accountNumberCopied: 'Kontonummer wird in die Zwischenablage kopiert',
  depositCloseConfirmation: 'Möchten Sie diese Einzahlung wirklich schließen?',
  validationError: 'Bitte überprüfen Sie die Richtigkeit des Ausfüllens der Formularfelder',
  chartEmpty: 'Keine Daten zum Anzeigen',
  chartBalloon: {
    deposited: 'Eingezahlt',
    withdrawals: 'Abhebungen',
    profit: 'Profitieren',
    referrals: 'Empfehlungen'
  },
  days_decl: 'Tag.Tage.Tage',
  weeks_decl: 'Woche.Wochen.Wochen',
  toast: {
    title: ['Erfolg', 'Fehler', 'Warnung', 'Informationen'],
    close: 'Schließen '
  },
  chartDepositBalloon: {
    date: 'Datum',
    interest: 'Interesse',
    profit: 'Profitieren'
  },
  calcChart: {
    depositAmount: 'Anzahlung',
    netProfit: 'Reingewinn',
    totalProfit: 'Gesamtgewinn'
  },
  month: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  pincodeCopied: 'Zahlungs-PIN erfolgreich kopiert',
  walletAddressCopied: 'Wallet-Adresse erfolgreich kopiert'
};
exports.locale_de = locale_de;

},{}],49:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_en = void 0;
var locale_en = {
  referralLinkCopied: 'Referral link successfully copied to the clipboard',
  embedCodeCopied: 'The embed code is copied to the clipboard',
  accountNumberCopied: 'Account number is copied to the clipboard',
  depositCloseConfirmation: 'Are you sure you want to close this deposit?',
  validationError: 'Please check the correctness of filling the form fields',
  chartEmpty: 'No data to display',
  chartBalloon: {
    deposited: 'Deposited',
    withdrawals: 'Withdrawals',
    profit: 'Profit',
    referrals: 'Referrals'
  },
  days_decl: 'day.days.days',
  weeks_decl: 'week.weeks.weeks',
  toast: {
    title: ['Success', 'Error', 'Warning', 'Information'],
    close: 'Close'
  },
  chartDepositBalloon: {
    date: 'Date',
    interest: 'Interest',
    profit: 'Profit'
  },
  calcChart: {
    depositAmount: 'Deposit',
    netProfit: 'Net Profit',
    totalProfit: 'Total Profit'
  },
  month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  pincodeCopied: 'Payment PIN successfully copied',
  walletAddressCopied: 'Address successfully copied'
};
exports.locale_en = locale_en;

},{}],50:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_es = void 0;
var locale_es = {
  referralLinkCopied: 'El enlace de referencia se copió correctamente en el portapapeles',
  embedCodeCopied: 'El código de inserción se copia en el portapapeles.',
  accountNumberCopied: 'El número de cuenta se copia en el portapapeles.',
  depositCloseConfirmation: '¿Estás segura de que quieres cerrar este depósito?',
  validationError: 'Por favor, compruebe la exactitud de la cumplimentación de los campos del formulario.',
  chartEmpty: 'no hay información para mostrar',
  chartBalloon: {
    deposited: 'Depositada',
    withdrawals: 'Retiros',
    profit: 'Ganancia',
    referrals: 'Referencias'
  },
  days_decl: 'día.dias.dias',
  weeks_decl: 'semana.semanas.semanas',
  toast: {
    title: ['Éxito', 'Error', 'Advertencia', 'Información'],
    close: 'Cerrar'
  },
  chartDepositBalloon: {
    date: 'Fecha',
    interest: 'Interesar',
    profit: 'Ganancia'
  },
  calcChart: {
    depositAmount: 'Depositar',
    netProfit: 'Beneficio neto',
    totalProfit: 'Beneficio total'
  },
  month: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  pincodeCopied: 'PIN de pago copiado correctamente',
  walletAddressCopied: 'La dirección de Wallet se copió correctamente'
};
exports.locale_es = locale_es;

},{}],51:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_fr = void 0;
var locale_fr = {
  referralLinkCopied: 'Lien de parrainage copié avec succès dans le presse-papiers',
  embedCodeCopied: 'Le code d\'intégration est copié dans le presse-papiers',
  accountNumberCopied: 'Le numéro de compte est copié dans le presse-papiers',
  depositCloseConfirmation: 'Voulez-vous vraiment clôturer ce dépôt ?',
  validationError: 'Veuillez vérifier l\'exactitude de remplir les champs du formulaire',
  chartEmpty: 'Aucune donnée à afficher',
  chartBalloon: {
    deposited: 'Déposée',
    withdrawals: 'Retraits',
    profit: 'Profit',
    referrals: 'Références'
  },
  days_decl: 'journée.jours.jours',
  weeks_decl: 'semaine.semaines.semaines',
  toast: {
    title: ['Succès', 'Erreur', 'Avertissement', 'Information'],
    close: 'Fermer'
  },
  chartDepositBalloon: {
    date: 'Date',
    interest: 'L\'intérêt',
    profit: 'Profit'
  },
  calcChart: {
    depositAmount: 'Verser',
    netProfit: 'Bénéfice net',
    totalProfit: 'Bénéfice total'
  },
  month: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  pincodeCopied: 'Code PIN de paiement copié avec succès',
  walletAddressCopied: 'L\'adresse du portefeuille a bien été copiée'
};
exports.locale_fr = locale_fr;

},{}],52:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_in = void 0;
var locale_in = {
  referralLinkCopied: 'रेफ़रल लिंक सफलतापूर्वक क्लिपबोर्ड पर कॉपी किया गया',
  embedCodeCopied: 'एम्बेड कोड को क्लिपबोर्ड पर कॉपी किया गया है',
  accountNumberCopied: 'खाता संख्या क्लिपबोर्ड पर कॉपी की गई है',
  depositCloseConfirmation: 'क्या आप वाकई इस जमा राशि को बंद करना चाहते हैं?',
  validationError: 'कृपया फ़ॉर्म फ़ील्ड भरने की शुद्धता की जाँच करें',
  chartEmpty: 'प्रदर्शित करने के लिए कोई डेटा नहीं',
  chartBalloon: {
    deposited: 'जमा किया',
    withdrawals: 'निकासी',
    profit: 'फायदा',
    referrals: 'रेफरल'
  },
  days_decl: 'दिन.दिन.दिन',
  weeks_decl: 'सप्ताह.हफ्तों.हफ्तों',
  toast: {
    title: ['सफलता', 'त्रुटि', 'चेतावनी', 'सूचना'],
    close: 'बंद करे'
  },
  chartDepositBalloon: {
    date: 'दिनांक',
    interest: 'ब्याज',
    profit: 'फायदा'
  },
  calcChart: {
    depositAmount: 'जमा',
    netProfit: 'शुद्ध लाभ',
    totalProfit: 'कुल लाभ'
  },
  month: ['जनवरी', 'फरवरी', 'मार्च', 'अप्रैल', 'मई', 'जून', 'जुलाई', 'अगस्त', 'सितंबर', 'अक्टूबर', 'नवंबर', 'दिसंबर'],
  pincodeCopied: 'भुगतान पिन सफलतापूर्वक कॉपी किया गया',
  walletAddressCopied: 'वॉलेट पता सफलतापूर्वक कॉपी किया गया'
};
exports.locale_in = locale_in;

},{}],53:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_it = void 0;
var locale_it = {
  referralLinkCopied: 'Link di riferimento copiato con successo negli appunti',
  embedCodeCopied: 'Il codice di incorporamento viene copiato negli appunti',
  accountNumberCopied: 'Il numero di conto viene copiato negli appunti',
  depositCloseConfirmation: 'Sei sicuro di voler chiudere questo deposito?',
  validationError: 'Si prega di verificare la correttezza della compilazione dei campi del modulo',
  chartEmpty: 'nessun dato da visualizzare',
  chartBalloon: {
    deposited: 'Depositato',
    withdrawals: 'Prelievi',
    profit: 'Profitto',
    referrals: 'Referenti'
  },
  days_decl: 'giorno.giorni.giorni',
  weeks_decl: 'settimana.settimane.weeks',
  toast: {
    title: ['Successo', 'Errore', 'Avviso', 'Informazioni'],
    close: 'Chiudere'
  },
  chartDepositBalloon: {
    date: 'Data',
    interest: 'Interesse',
    profit: 'Profitto'
  },
  calcChart: {
    depositAmount: 'Depositare',
    netProfit: 'Profitto netto',
    totalProfit: 'Profitto totale'
  },
  month: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
  pincodeCopied: 'PIN di pagamento copiato con successo',
  walletAddressCopied: 'Indirizzo del portafoglio copiato con successo'
};
exports.locale_it = locale_it;

},{}],54:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_ja = void 0;
var locale_ja = {
  referralLinkCopied: '参照リンクがクリップボードに正常にコピーされました',
  embedCodeCopied: 'クリップボードにコピーされた埋め込み',
  copiedToClipboard: 'クリップボードにコピーされたデータ',
  depositCloseConfirmation: 'この預金を閉じてもよろしいですか？',
  validationError: 'フォームフィールドが正しく入力されていることを確認してください',
  chartEmpty: '表示するデータがありません',
  chartBalloon: {
    deposited: '投資',
    withdrawals: '引きこもった',
    profit: '利益',
    referrals: '照会'
  },
  days_decl: '日.当時の.日々',
  weeks_decl: '一週間.数週間.数週間',
  toast: {
    title: ['終わり', 'エラー', '注意', '情報'],
    close: '選ぶ'
  },
  chartDepositBalloon: {
    date: '日にち',
    interest: '入札',
    profit: '利益'
  },
  calcChart: {
    depositAmount: '預り金',
    netProfit: 'Прибыль',
    totalProfit: '利益総額'
  },
  month: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  pincodeCopied: '支払いPINが正常にコピーされました',
  walletAddressCopied: 'ウォレットアドレスが正常にコピーされました'
};
exports.locale_ja = locale_ja;

},{}],55:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_ko = void 0;
var locale_ko = {
  referralLinkCopied: '추천 링크가 클립보드에 성공적으로 복사되었습니다.',
  embedCodeCopied: '내장 코드가 클립보드에 복사됩니다.',
  accountNumberCopied: '계정 번호가 클립보드에 복사됩니다.',
  depositCloseConfirmation: '이 입금을 종료하시겠습니까?',
  validationError: '양식 필드 작성의 정확성을 확인하십시오',
  chartEmpty: '표시 할 내용이 없습니다',
  chartBalloon: {
    deposited: '예치',
    withdrawals: '출금',
    profit: '이익',
    referrals: '추천'
  },
  days_decl: '일.날.날',
  weeks_decl: '주.주.주',
  toast: {
    title: ['성공', '오류', '경고', '정보'],
    close: '닫기'
  },
  chartDepositBalloon: {
    date: '날짜',
    interest: '관심',
    profit: '이익'
  },
  calcChart: {
    depositAmount: '보증금',
    netProfit: '순이익',
    totalProfit: '총 이윤'
  },
  month: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  pincodeCopied: '결제 PIN이 복사되었습니다.',
  walletAddressCopied: '지갑 주소가 성공적으로 복사되었습니다.'
};
exports.locale_ko = locale_ko;

},{}],56:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_mn = void 0;
var locale_mn = {
  referralLinkCopied: 'Лавлах холбоосыг санах ойд амжилттай хуулав',
  embedCodeCopied: 'Оруулсан кодыг түр санах ой руу хуулж байна',
  accountNumberCopied: 'Дансны дугаарыг санах ойд хуулж байна',
  depositCloseConfirmation: 'Та энэ хадгаламжийг хаахдаа итгэлтэй байна уу?',
  validationError: 'Маягтын талбарыг бөглөх зөв эсэхийг шалгана уу',
  chartEmpty: 'Харуулах өгөгдөл байхгүй байна',
  chartBalloon: {
    deposited: 'Хадгалагдсан',
    withdrawals: 'Татаж авах',
    profit: 'Ашиг',
    referrals: 'Лавлагаа'
  },
  days_decl: 'өдөр.өдрүүд.өдрүүд',
  weeks_decl: 'долоо хоног.долоо хоног.долоо хоног',
  toast: {
    title: ['Амжилт', 'Алдаа', 'Анхааруулга', 'Мэдээлэл'],
    close: 'Xаах'
  },
  chartDepositBalloon: {
    date: 'Огноо',
    interest: 'Хүү',
    profit: 'Ашиг'
  },
  calcChart: {
    depositAmount: 'Хадгаламж',
    netProfit: 'Цэвэр ашиг',
    totalProfit: 'Нийт ашиг'
  },
  month: ['Нэгдүгээр сар', 'Хоёрдугаар сар', 'Гуравдугаар сар', 'Дөрөвдүгээр сар', 'Тавдугаар сар', 'Зургадугаар сар', 'Долдугаар сар', 'Наймдугаар сар', 'Есдүгээр сар', 'Аравдугаар сар', 'Арваннэгдүгээр сар', 'Арванхоёрдугаар сар'],
  pincodeCopied: 'Төлбөрийн ПИН амжилттай хуулагдлаа',
  walletAddressCopied: 'Түрийвчний хаягийг амжилттай хуулж авлаа'
};
exports.locale_mn = locale_mn;

},{}],57:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_pt = void 0;
var locale_pt = {
  referralLinkCopied: 'Link de referência copiado com sucesso para a área de transferência',
  embedCodeCopied: 'O código de incorporação é copiado para a área de transferência',
  accountNumberCopied: 'O número da conta é copiado para a área de transferência',
  depositCloseConfirmation: 'Tem certeza que deseja fechar este depósito?',
  validationError: 'Verifique se o preenchimento dos campos do formulário está correto',
  chartEmpty: 'Sem dados para exibir',
  chartBalloon: {
    deposited: 'Depositada',
    withdrawals: 'Retiradas',
    profit: 'Lucro',
    referrals: 'Referências'
  },
  days_decl: 'day.dias.dias',
  weeks_decl: 'semana.semanas.semanas',
  toast: {
    title: ['Sucesso', 'Erro', 'Aviso', 'Informação'],
    close: 'Fechar'
  },
  chartDepositBalloon: {
    date: 'Encontro',
    interest: 'Interesse',
    profit: 'Lucro'
  },
  calcChart: {
    depositAmount: 'Depósito',
    netProfit: 'Lucro líquido',
    totalProfit: 'Lucro total'
  },
  month: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  pincodeCopied: 'PIN de pagamento copiado com sucesso',
  walletAddressCopied: 'Endereço da carteira copiado com sucesso'
};
exports.locale_pt = locale_pt;

},{}],58:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_ru = void 0;
var locale_ru = {
  referralLinkCopied: 'Реферальная ссылка успешно скопирована в буфер обмена',
  embedCodeCopied: 'Код для вставки скопирован в буфер обмена',
  copiedToClipboard: 'Данные скопированы в буфер обмена',
  depositCloseConfirmation: 'Вы действительно хотите закрыть данный депозит?',
  validationError: 'Пожалуйста, проверьте правильность заполнения полей формы',
  chartEmpty: 'Нет данных для отображения',
  chartBalloon: {
    deposited: 'Инвестировано',
    withdrawals: 'Выведено',
    profit: 'Прибыль',
    referrals: 'Реферальные'
  },
  days_decl: 'день.дня.дней',
  weeks_decl: 'неделя.недели.недель',
  toast: {
    title: ['Выполнено', 'Ошибка', 'Внимание', 'Информация'],
    close: 'Закрыть'
  },
  chartDepositBalloon: {
    date: 'Дата',
    interest: 'Ставка',
    profit: 'Прибыль'
  },
  calcChart: {
    depositAmount: 'Депозит',
    netProfit: 'Прибыль',
    totalProfit: 'Общая прибыль'
  },
  month: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
  pincodeCopied: 'Платежный PIN-код успешно скопирован',
  walletAddressCopied: 'Адрес кошелька успешно скопирован'
};
exports.locale_ru = locale_ru;

},{}],59:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_tr = void 0;
var locale_tr = {
  referralLinkCopied: 'Yönlendirme bağlantısı panoya başarıyla kopyalandı',
  embedCodeCopied: 'Gömme kodu panoya kopyalanır',
  accountNumberCopied: 'Hesap numarası panoya kopyalandı',
  depositCloseConfirmation: 'Bu depozitoyu kapatmak istediğinizden emin misiniz?',
  validationError: 'Lütfen form alanlarını doldurmanın doğruluğunu kontrol edin',
  chartEmpty: 'gösterilecek bilgi yok',
  chartBalloon: {
    deposited: 'Yatırıldı',
    withdrawals: 'para çekme',
    profit: 'Kâr',
    referrals: 'tavsiyeler'
  },
  days_decl: 'gün.günler.günler',
  weeks_decl: 'hafta.haftalar.haftalar',
  toast: {
    title: ['Başarı', 'Hata', 'Uyarı', 'Bilgi'],
    close: 'Kapat'
  },
  chartDepositBalloon: {
    date: 'Tarih',
    interest: 'Faiz',
    profit: 'Kâr'
  },
  calcChart: {
    depositAmount: 'Depozito',
    netProfit: 'Net kazanç',
    totalProfit: 'Toplam kar'
  },
  month: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
  pincodeCopied: 'Ödeme PIN\'i başarıyla kopyalandı',
  walletAddressCopied: 'Cüzdan adresi başarıyla kopyalandı'
};
exports.locale_tr = locale_tr;

},{}],60:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale_vi = void 0;
var locale_vi = {
  referralLinkCopied: 'Đã sao chép thành công liên kết giới thiệu vào khay nhớ tạm',
  embedCodeCopied: 'Đã sao chép nhúng vào khay nhớ tạm',
  copiedToClipboard: 'Đã sao chép dữ liệu vào khay nhớ tạm',
  depositCloseConfirmation: 'Bạn có chắc chắn muốn đóng khoản tiền gửi này không?',
  validationError: 'Vui lòng kiểm tra xem các trường biểu mẫu đã được điền chính xác chưa',
  chartEmpty: 'Không có dữ liệu để hiển thị',
  chartBalloon: {
    deposited: 'Đã đầu tư',
    withdrawals: 'Rút tiền',
    profit: 'Lợi nhuận',
    referrals: 'Giới thiệu'
  },
  days_decl: 'ngày.ngày.ngày',
  weeks_decl: 'tuần.tuần.tuần',
  toast: {
    title: ['Xong', 'Lỗi', 'Chú ý', 'Thông tin'],
    close: 'Gần'
  },
  chartDepositBalloon: {
    date: 'Ngày',
    interest: 'Đấu thầu',
    profit: 'Lợi nhuận'
  },
  calcChart: {
    depositAmount: 'Gửi tiền',
    netProfit: 'Lợi nhuận',
    totalProfit: 'Tổng lợi nhuận'
  },
  month: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
  pincodeCopied: 'Mã PIN thanh toán đã được sao chép thành công',
  walletAddressCopied: 'Đã sao chép thành công địa chỉ ví'
};
exports.locale_vi = locale_vi;

},{}],61:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = angular.module('sx.clipboard', []).service('$clipboard',
/* @ngInject */
["$q", function ($q) {
  this.supported = 'queryCommandSupported' in document && document.queryCommandSupported('copy');

  this.copy = function (text) {
    var deferred = $q.defer(),
        node = document.createElement('div'),
        range = document.createRange(),
        selection = window.getSelection();
    node.style.position = 'fixed';
    node.style.opacity = '0';
    node.innerHTML = text;
    document.body.appendChild(node);
    selection.removeAllRanges();
    range.selectNode(node);
    selection.addRange(range);

    try {
      var successful = document.execCommand('copy');
      if (!successful) throw successful;
      deferred.resolve(successful);
    } catch (err) {
      deferred.reject(err);
    } finally {
      selection.removeAllRanges();
      document.body.removeChild(node);
    }

    return deferred.promise;
  };

  return this;
}]).directive('sxClipboard',
/* @ngInject */
["$clipboard", "$toast", function ($clipboard, $toast) {
  return {
    restrict: 'EA',
    link: function link(scope, elem, attr) {
      var lang = window.LANG || 'en';
      var msgs = {
        en: 'Data copied to clipboard',
        ru: 'Данные скопированы в буфер обмена'
      };
      var msg = msgs[lang] !== undefined ? msgs[lang] : msgs['en'];
      elem.bind('click', function (event) {
        var text = attr.sxClipboard || null;
        $clipboard.copy(text).then(function (res) {
          $toast.info(msg);
        });
      });
    }
  };
}]);

exports.default = _default;

},{}],62:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = angular.module('sx.modal', []).factory('$modal',
/* @ngInject */
["$animate", "$document", "$compile", "$controller", "$http", "$rootScope", "$q", "$templateRequest", "$timeout", function ($animate, $document, $compile, $controller, $http, $rootScope, $q, $templateRequest, $timeout) {
  //  Get the body of the document, we'll add the modal to this.
  var body = $document.find('body');

  function ModalService() {
    var self = this; //  Returns a promise which gets the template, either
    //  from the template parameter or via a request to the
    //  template url parameter.

    var getTemplate = function getTemplate(template, templateUrl) {
      var deferred = $q.defer();

      if (template) {
        deferred.resolve(template);
      } else if (templateUrl) {
        $templateRequest(templateUrl, true).then(function (template) {
          deferred.resolve(template);
        }, function (error) {
          deferred.reject(error);
        });
      } else {
        deferred.reject('No template or templateUrl has been specified.');
      }

      return deferred.promise;
    }; //  Adds an element to the DOM as the last child of its container
    //  like append, but uses $animate to handle animations. Returns a
    //  promise that is resolved once all animation is complete.


    var appendChild = function appendChild(parent, child) {
      var children = parent.children();

      if (children.length > 0) {
        return $animate.enter(child, parent, children[children.length - 1]);
      }

      return $animate.enter(child, parent);
    };

    self.showModal = function (options) {
      //  Create a deferred we'll resolve when the modal is ready.
      var deferred = $q.defer(); //  Validate the input parameters.

      var controllerName = options.controller;

      if (!controllerName) {
        deferred.reject('No controller has been specified.');
        return deferred.promise;
      } // body lag offset


      var paddingOffset = window.innerWidth - document.body.offsetWidth + 'px'; //  Get the actual html of the template.

      getTemplate(options.template, options.templateUrl).then(function (template) {
        //  Create a new scope for the modal.
        var modalScope = (options.scope || $rootScope).$new(); //  Create the inputs object to the controller - this will include
        //  the scope, as well as all inputs provided.
        //  We will also create a deferred that is resolved with a provided
        //  close function. The controller can then call 'close(result)'.
        //  The controller can also provide a delay for closing - this is
        //  helpful if there are closing animations which must finish first.

        var closeDeferred = $q.defer();
        var closedDeferred = $q.defer();
        var inputs = {
          $scope: modalScope,
          close: function close(result, delay) {
            if (delay === undefined || delay === null) delay = 0; // set modal flag

            $rootScope.modal = false; // remove body padding

            body.css({
              'padding-right': 0
            });
            $timeout(function () {
              //  Resolve the 'close' promise.
              closeDeferred.resolve(result); //  Let angular remove the element and wait for animations to finish.

              $animate.leave(modalElement).then(function () {
                //  Resolve the 'closed' promise.
                closedDeferred.resolve(result); //  We can now clean up the scope

                modalScope.$destroy(); //  Unless we null out all of these objects we seem to suffer
                //  from memory leaks, if anyone can explain why then I'd
                //  be very interested to know.

                inputs.close = null;
                deferred = null;
                closeDeferred = null;
                modal = null;
                inputs = null;
                modalElement = null;
                modalScope = null;
              });
            }, delay);
          }
        }; //  If we have provided any inputs, pass them to the controller.

        if (options.inputs) angular.extend(inputs, options.inputs); //  Compile then link the template element, building the actual element.
        //  Set the $element on the inputs so that it can be injected if required.

        var linkFn = $compile(template);
        var modalElement = linkFn(modalScope);
        inputs.$element = modalElement; //  Create the controller, explicitly specifying the scope to use.

        var controllerObjBefore = modalScope[options.controllerAs];
        var modalController = $controller(options.controller, inputs, false, options.controllerAs);

        if (options.controllerAs && controllerObjBefore) {
          angular.extend(modalController, controllerObjBefore);
        } //  Finally, append the modal to the dom.


        if (options.appendElement) {
          // append to custom append element
          appendChild(options.appendElement, modalElement);
        } else {
          // append to body when no custom append element is specified
          appendChild(body, modalElement);
        } // set modal flag


        $rootScope.modal = true; // set body padding

        body.css({
          'padding-right': paddingOffset
        }); //  We now have a modal object...

        var modal = {
          controller: modalController,
          scope: modalScope,
          element: modalElement,
          close: closeDeferred.promise,
          closed: closedDeferred.promise
        }; //  ...which is passed to the caller via the promise.

        deferred.resolve(modal);
      }).then(null, function (error) {
        // 'catch' doesn't work in IE8.
        deferred.reject(error);
      });
      return deferred.promise;
    };
  }

  return new ModalService();
}]);

exports.default = _default;

},{}],63:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

// Modifies $httpProvider for correct server communication (POST variable format)
var _default = angular.module('sx.postfix', [],
/* @ngInject */
["$httpProvider", function ($httpProvider) {
  // Use x-www-form-urlencoded Content-Type
  $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
  $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; // Override $http service's default transformRequest

  $httpProvider.defaults.transformRequest = [function (data) {
    /**
     * The workhorse; converts an object to x-www-form-urlencoded serialization.
     * @param {Object} obj
     * @return {String}
     */
    var param = function param(obj) {
      var query = '';
      var name, value, fullSubName, subName, subValue, innerObj, i;

      for (name in obj) {
        value = obj[name];

        if (value instanceof Array) {
          for (i = 0; i < value.length; ++i) {
            subValue = value[i];
            fullSubName = name + '[' + i + ']';
            innerObj = {};
            innerObj[fullSubName] = subValue;
            query += param(innerObj) + '&';
          }
        } else if (value instanceof Object) {
          for (subName in value) {
            subValue = value[subName];
            fullSubName = name + '[' + subName + ']';
            innerObj = {};
            innerObj[fullSubName] = subValue;
            query += param(innerObj) + '&';
          }
        } else if (value !== undefined && value !== null) {
          query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
        }
      }

      return query.length ? query.substr(0, query.length - 1) : query;
    };

    return angular.isObject(data) && String(data) !== '[object File]' ? param(data) : data;
  }];
}]);

exports.default = _default;

},{}],64:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = angular.module('sx.storage', []).service('$storage',
/* @ngInject */
function () {
  var self = this;
  self.key = 'sxStorage';

  this.data = function () {
    return readStorage();
  };

  this.set = function (values) {
    if (!angular.isObject(values)) return false;
    var st = readStorage(),
        count = 0;

    if (!angular.isObject(st)) {
      st = {};
    }

    angular.forEach(values, function (value, key) {
      if (st[key] !== value) {
        ++count;
        st[key] = value;
      }
    });
    writeStorage(st);
    return count > 0;
  };

  this.get = function (key, def) {
    var st = readStorage();

    if (angular.isObject(st) && st[key] !== undefined) {
      return st[key];
    }

    if (def !== undefined) {
      return def;
    }

    return null;
  };

  this.forget = function (keys) {
    if (!angular.isArray(keys)) return false;
    var st = readStorage(),
        count = 0;
    if (!angular.isObject(st)) return false;
    angular.forEach(keys, function (key) {
      if (st[key] !== undefined) {
        delete st[key];
        ++count;
      }
    });
    writeStorage(st);
    return count > 0;
  };

  this.has = function (key) {
    var st = readStorage();
    if (!angular.isObject(st)) return false;
    return st[key] !== undefined;
  };

  this.clear = function () {
    localStorage.removeItem(self.key);
    return true;
  };

  function readStorage() {
    var data = localStorage.getItem(self.key);

    if (data !== null) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return null;
      }
    }

    return null;
  }

  function writeStorage(data) {
    return localStorage.setItem(self.key, JSON.stringify(data));
  }

  return this;
});

exports.default = _default;

},{}],65:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _locale = _interopRequireDefault(require("../locale"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = angular.module('sx.toast', []).run(
/* @ngInject */
["$templateCache", function ($templateCache) {
  var template = "\n      <div class=\"msg animated-fast fadeIn\" tabindex=\"-1\">\n         <div class=\"msg-item %TYPE%\">\n            <div class=\"msg-item__title\">%TITLE%</div>\n            <div class=\"msg-item__text\">%MESSAGE%</div>\n            <div class=\"msg-item__close\">\n               <svg>\n                  <use xlink:href=\"/assets/app/img/sprite.svg#cancel\"></use>\n               </svg>\n            </div>\n         </div>\n      </div>";
  $templateCache.put('toast', template);
}]).service('$toast',
/* @ngInject */
["$templateCache", "$document", "$timeout", function ($templateCache, $document, $timeout) {
  var _this = this;

  var config = {
    rootElementClass: '.msg',
    closeElementClass: '.msg-item__close',
    delay: 10000
  };
  this.body = angular.element('body');
  this.timer = null;

  this.show = function (type, msg) {
    var tpl = _this.getTemplate(type, msg),
        items = angular.element(config.rootElementClass);

    if (items.length) {
      _this.hide();
    }

    $document.on('keydown keypress', _this.hideOnEsc);
    tpl.find(config.closeElementClass).on('click', _this.hide);

    _this.body.append(tpl);

    _this.timer = $timeout(function () {
      return _this.hide();
    }, config.delay);
  };

  this.hide = function () {
    angular.element(config.rootElementClass).remove();
    $document.off('keydown keypress', _this.hideOnEsc);

    if (_this.timer) {
      $timeout.cancel(_this.timer);
      _this.timer = null;
    }
  };

  this.hideOnEsc = function (event) {
    if (event.which === 27) _this.hide();
  };

  this.getTemplate = function (type, msg) {
    var tpl = $templateCache.get('toast'),
        title;

    switch (type) {
      case 'success':
        title = (0, _locale.default)('toast.title.0');
        break;

      case 'error':
        title = (0, _locale.default)('toast.title.1');
        break;

      case 'warning':
        title = (0, _locale.default)('toast.title.2');
        break;

      default:
        title = (0, _locale.default)('toast.title.3');
    }

    tpl = tpl.replace('%TYPE%', type);
    tpl = tpl.replace('%TITLE%', title);
    tpl = tpl.replace('%MESSAGE%', msg);
    return angular.element(tpl);
  };

  this.success = function (msg) {
    return _this.show('success', msg);
  };

  this.error = function (msg) {
    return _this.show('error', msg);
  };

  this.warning = function (msg) {
    return _this.show('warning', msg);
  };

  this.info = function (msg) {
    return _this.show('info', msg);
  };
}]);

exports.default = _default;

},{"../locale":46}],66:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Run;
Run.$inject = ["Global", "$rootScope", "$storage", "$window", "$toast"];

/* @ngInject */
function Run(Global, $rootScope, $storage, $window, $toast) {
  var viewBreakPoint = 991;
  $rootScope.busy = false;
  $rootScope.modal = false;
  $rootScope.global = Global;
  $rootScope.global.languages = [{
    code: 'en',
    name: 'English',
    type: 'base'
  }, {
    code: 'zh-CN',
    name: '中国人',
    type: 'google'
  }, {
    code: 'ko',
    name: '한국어',
    type: 'google'
  }, {
    code: 'es',
    name: 'Espanol',
    type: 'google'
  }, {
    code: 'de',
    name: 'Deutsch',
    type: 'google'
  }, {
    code: 'it',
    name: 'Italiano',
    type: 'google'
  }, {
    code: 'fr',
    name: 'Le français',
    type: 'google'
  }, //{code:'mn', name:'Монгол', type:'google'},
  {
    code: 'pt',
    name: 'Português',
    type: 'google'
  }, {
    code: 'vi',
    name: 'Tiếng việt',
    type: 'google'
  }, {
    code: 'ja',
    name: '日本人',
    type: 'google'
  }, {
    code: 'id',
    name: 'Indonesia',
    type: 'google'
  }, {
    code: 'tr',
    name: 'Türk',
    type: 'google'
  } //{code:'ru', name:'Русский', type:'base'},
  //{code:'pl', name:'Polskie', type:'google'},
  //{code:'zh-CN', name:'中国人', type:'google'},
  //{code:'ur', name:'اردو', type:'google'},
  //{code:'ar', name:'عرب', type:'google'},
  ];

  $rootScope.getCurrency = function () {
    var def = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'usdt';
    var code = $storage.get('currency', def);

    if (!$storage.has('currency')) {
      $storage.set({
        currency: code
      });
    }

    return code;
  };

  $rootScope.setCurrency = function (code) {
    var broadcast = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    if ($rootScope.global.currency !== code) {
      $rootScope.global.currency = code;
      $storage.set({
        currency: code
      });

      if (broadcast) {
        $rootScope.$broadcast('CurrencyChange', code);
      }
    }
  };

  $rootScope.getLanguage = function () {
    if (!$storage.has('language')) {
      return $rootScope.global.languages.find(function (value) {
        return value.code === LANG;
      }) || $rootScope.global.languages[0];
    }

    return $storage.get('language');
  };

  $rootScope.setLanguage = function (language) {
    return $storage.set({
      language: language
    });
  };

  $rootScope.changeLanguage = function (language) {
    $rootScope.setLanguage(language);
    var domain = window.location.hostname,
        expires = new Date(0).toUTCString(),
        value = "/".concat(LANG, "/").concat(language.code);

    if (language.type === 'base') {
      document.cookie = "googtrans=;expires=".concat(expires, ";path=/");
      document.cookie = "googtrans=;domain=.".concat(domain, ";expires=").concat(expires, ";path=/");
    } else {
      document.cookie = "googtrans=".concat(value, ";path=/");
      document.cookie = "googtrans=".concat(value, ";domain=.").concat(domain, ";path=/");
    }

    return location.href = "?lang=".concat(language.code);
  };

  $rootScope.getViewMode = function () {
    var width = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : viewBreakPoint;
    return $window.innerWidth > width ? 'desktop' : 'mobile';
  };

  $rootScope.getViewWidth = function () {
    return parseInt($window.innerWidth);
  };

  $rootScope.scrollTop = function () {
    var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var speed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
    $('html, body').animate({
      scrollTop: offset
    }, speed);
  };

  $rootScope.scrollTo = function (anchor) {
    var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;
    var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var cb = arguments.length > 3 ? arguments[3] : undefined;
    if (!anchor) return false;
    var target = angular.element('#' + anchor);

    if (target.length) {
      $('html,body').animate({
        scrollTop: target.offset().top - offset
      }, duration);
      location.hash = anchor;

      if (typeof cb === 'function') {
        cb(anchor);
      }
    }
  };

  angular.element($window).bind('resize', function () {
    $rootScope.global.viewMode = $rootScope.getViewMode();
    $rootScope.global.viewWidth = $rootScope.getViewWidth();
    $rootScope.$apply();
  });

  function onInit() {
    $rootScope.global.language = $rootScope.getLanguage();
    $rootScope.global.currency = $rootScope.getCurrency();
    $rootScope.global.viewMode = $rootScope.getViewMode();
    $rootScope.global.viewWidth = $rootScope.getViewWidth();

    if (window.flash !== undefined) {
      $toast.show(window.flash.type, window.flash.text);
    }
  }

  onInit();
}

;

},{}]},{},[1]);
